import request from '@/network/request.js'
/**
 * 获取观看列表信息
 */
export function lists_watchrecord(data) {
	return request({
		url: '/admin/dramavideoorder/lists_watchrecord',
		method: 'post',
		data,
	})
}

/**
 * 增加|修改主视频列表信息
 */
// export function add_up_video_price(data) {
// 	return request({
// 		url: '/admin/dramavideoorder/add_up_price',
// 		method: 'post',
// 		data,
// 	})
// }

/**
 * 删除|批量观看的视频信息
 */
export function dels_watchrecord(data) {
	return request({
		url: '/admin/dramavideoorder/dels_lists_watchrecord',
		method: 'post',
		data,
	})
}



